import Modules from "./modules";

/**
 * Class Counter
 */
class Counter {
  /**
   * Class constructor
   * @param {HTMLElement} module
   */
  constructor(module) {
    this.module = module;
    this.items = this.module.querySelectorAll(".m-counter__numbers-number");

    this.counterAnimation();
  }

  /**
   * Animation
   */
  counterAnimation() {
    for (let index = 0; index < this.items.length; index++) {
      const numbers = this.items[index].innerHTML;
      gsap.fromTo(
        this.items[index],
        {
          textContent: 0,
        },
        {
          textContent: numbers,
          duration: 3.5,
          ease: Power4.easeOut,
          scrollTrigger: {
            trigger: this.module,
            start: "top 90%",
            end: "top 30%",
          },
          snap: { textContent: 1 },
          stagger: 1,
        }
      );
    }
  }

  /**
   * Returns the class name
   * @return {string}
   */
  static getName() {
    return "js-counter";
  }
}

new Modules(Counter);
